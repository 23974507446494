.journal-container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  width: 80%;
  margin: auto;
  margin-top: 2%;
  background-color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #454545;
}

.habit-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  padding: 2%;
  padding-top: 1%;
  border-radius: 10px;
  max-height: 40vh;
  overflow-y: scroll;
}

.input-wrapper {
  width: 100%;
  padding: 2%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin-top: 2%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.input-wrapper textarea {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: none;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin-top: 1%;
}

.input-wrapper div {
  font-size: 20px;
}
