.app-header {
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  align-items: flex-start;
  color: #454545;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.app-header h1 {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
}

.app-header h1:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.app-header button {
  margin-right: 2%;
}
