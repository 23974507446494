.loader-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.loader {
  border: 16px solid #f7f7f7;
  border-top: 16px solid #2bf27175;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loader-button {
  font-size: 20px;
  color: rgba(15, 112, 93, 0.6);
  border-radius: 5px;
  padding: 1%;
  margin: auto;
  margin-top: 2%;
  border: 1px solid rgba(15, 112, 93, 0.3);
  background-color: white;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
}

.loader-button:hover {
  transform: scale(1.05);
  background-color: rgba(15, 112, 93, 0.6);
  color: white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 399px) {
  .loader-button {
    font-size: 10px;
  }
}
