.form-container {
  max-width: 75vw;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0 auto;
  padding: 20px;
}

.user-summary {
  width: 100%;
  font-size: 25px;
  margin-top: 4%;
  background-color: #fefefe;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: left;
  margin-bottom: 4%;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  line-height: 1.5em;
}

.user-summary:hover {
  transform: scale(1.08);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.user-summary-word {
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out,
    box-shadow 1s ease-in-out;
  color: #555555;
  border-radius: 10px;
}

.user-summary-word:hover {
  color: black;
  background-color: #16a08410;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.form-container h3 {
  font-size: 25px;
}
