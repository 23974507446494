.daily-poem {
  width: 75vw;
  margin: 0 auto;
}

.daily-poem div div {
  font-style: italic;
  text-align: center;
  width: 75%;
  margin: 0 auto;
  padding: 5%;
  padding-bottom: 0;
  padding-top: 0px;
  font-size: 20px;
}

@media only screen and (max-width: 399px) {
  .daily-poem div div {
    font-size: smaller;
    line-height: 1.5em;
  }
}
