.qa-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  color: #30413d;
}

.qa-form input[type='text'] {
  width: 88%;
  padding: 10px;
  margin-bottom: 4%;
  border: none;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 4px;
}

.qa-form input[type='text']:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(26, 188, 156, 0.3);
  border: 1px solid rgba(26, 188, 156, 0.3);
}

.qa-form button[type='submit'] {
  display: block;
  width: 20%;
  margin: 20px auto;
  padding: 10px;
  color: white;
  background-color: #1abc9c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.qa-form button[type='submit']:hover {
  background-color: #16a085;
  width: 30%;
}

.qa-form {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  width: 80%;
  margin: auto;
  margin-bottom: 2%;
  margin-top: 2%;
  border-radius: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.qa-form.open {
  max-height: 80vh;
  overflow-y: scroll;
  padding: 20px;
}
