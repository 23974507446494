.tree-item {
  color: #454545;
  margin: 5px 0;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: center;
  align-items: center;
}

.tree-seed {
  margin: auto;
  width: 100%;
  text-align: center;
  padding-top: 2%;
  margin-top: 2%;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 600;
  font-size: 20px;
}

.tree-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.generate-seed-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 50%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
  font-size: 15px;
  color: #454545;
  border: none;
  margin: 2%;
}

.generate-seed-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  color: #555555;
}

.tree-child-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tree-child-tab {
  width: 100%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  height: 5em;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.1s;
  align-items: center;
  display: flex;
  justify-content: center;
}

.tree-child-tab:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
}

.tree-child-tab-open {
  width: 100%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  height: 5em;
  transition: all 0.1s;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
}

.tree-child {
  min-height: 50vh;
  width: 100%;
}

@media only screen and (max-width: 399px) {
  .tree-child-tab {
    font-size: 10px;
    overflow: scroll;
  }

  .tree-child-tab-open {
    font-size: 12px;
    overflow-y: scroll;
  }
}
