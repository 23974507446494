.drawer {
  width: 80vw;
  height: 0vh;
  background: #ffffff;
  transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin-top: 2%;
}

.drawer.open {
  max-height: 100vh;
  min-height: 50vh;
  padding: 20px;
}

.learn-something-li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.learn-something-new {
  padding: 10px;
  border-radius: 5px;
  font-size: 10px;
  background-color: #ffffff;
  border: none;
  transition: all 0.2s ease;
  margin-top: 2%;
}

.learn-something-new:hover {
  cursor: pointer;
  box-shadow: 0px 0px 3px #16a0849b;
  color: #16a084;
  transform: scale(1.05);
}

.open-button {
  width: 40vw;
  padding: 20px;
  border-radius: 5px;
  font-size: 20px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
  margin-top: 2%;
}

.open-button:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px #16a0849b;
  transform: scale(1.05);
  color: #16a084;
}

.learn-something {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.learn-something-header {
  font-size: 25px;
  margin: auto;
  text-align: center;
  color: #454545;
  padding: 20px;
}

.learn-drawer {
  width: 80vw;
  max-height: 0;
  background: #ffffff;
  transition: height 0.5s, padding 0.5s;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin-top: 2%;
}

.learn-drawer form {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
}

.learn-drawer label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 75%;
}

.learn-drawer p {
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.learn-drawer input:focus {
  outline: none;
  box-shadow: 0px 0px 8px #2bf27175;
  transform: scale(1.05);
}

.learn-drawer input {
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin: auto;
}

.learn-drawer-button {
  color: white;
  background-color: #2bf27175;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: auto;
}

.learn-drawer-button:hover {
  background-color: #2bf271;
}

.learn-drawer.open {
  max-height: 100vh;
}

.delete-button {
  display: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(255, 0, 0, 0.466);
  transition: all 0.3s ease;
  margin: auto;
  float: right;
  color: #dedede;
  padding: 10px;
}

.delete-button:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 0, 0, 0.466);
  color: white;
}

.open-button:hover .delete-button {
  display: block;
}

.open-button p {
  width: 50%;
  float: left;
}

@media only screen and (max-width: 399px) {
  .open-button {
    padding: 1%;
    width: 80vw;
    padding-left: 2%;
  }
}
