.learn-something-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 80vw;
  margin: auto;
}

.learn-something-root-navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.learn-something-root-navigate-back {
  width: 20%;
  display: block;
  margin: 20px auto;
  padding: 10px;
  color: white;
  background-color: #b4b4b4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.learn-something-root-navigate-back:hover {
  background-color: #858585;
}

.learn-something-root-topic {
  width: 100%;
  text-align: center;
  font-size: 50px;
}

.learn-something-root-lesson {
  width: 80%;
}

.learn-something-child-tabs {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learn-something-child-tab {
  width: 100%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  height: 5em;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.1s;
  align-items: center;
  display: flex;
  justify-content: center;
}

.learn-something-child-tab:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
}

.learn-something-child-tab-open {
  width: 100%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  height: 5em;
  transition: all 0.1s;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.6);
}

.learn-something-child {
  min-height: 50vh;
  width: 80%;
}

@media only screen and (max-width: 399px) {
  .learn-something-root {
    width: 95vw;
    justify-content: space-between;
  }
  .learn-something-root-topic {
    text-align: left;
    font-size: 20px;
    width: 50%;
  }

  .learn-something-child-tab {
    font-size: 10px;
  }

  .learn-something-child-tab-open {
    font-size: 13px;
  }
}
