.App {
  color: #454545;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app-body {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2%;
  padding: 2%;
}
