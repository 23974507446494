.habit-mgr {
  width: 75vw;
  padding: 2%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.gen-habit-tip-button {
  font-size: 20px;
  color: white;
  border-radius: 5px;
  padding: 1%;
  margin: auto;
  margin-top: 2%;
  background-color: rgba(15, 112, 93, 0.6);
  border: none;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
}

.gen-habit-tip-button:hover {
  box-shadow: 0px 0px 6px rgba(15, 112, 93, 0.24);
  transform: scale(1.05);
  background-color: white;
  color: rgba(15, 112, 93, 0.6);
}

.habit-suggestions {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  min-height: 75vh;
  padding-right: 2%;
}

.habit {
  max-width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  border-radius: 10px;
  padding: 2%;
  width: 100%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.habit:hover {
  box-shadow: 0px 0px 6px rgba(15, 112, 93, 0.24);
  transform: scale(1.05);
  background-color: white;
  color: rgba(15, 112, 93, 0.6);
  cursor: pointer;
}

.habit-card {
  border-radius: 10px;
  padding: 2%;
  width: 100%;
}

.habit-actions {
  width: 100%;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.habit-actions.open {
  max-height: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.habit-mgr-body {
  display: flex;
}

.my-habits {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 75vh;
  padding-left: 4%;
}

@media only screen and (max-width: 399px) {
  .habit-mgr h1 {
    font-size: 20px;
  }

  .habit-mgr p {
    font-size: small;
  }

  .habit {
    border-radius: 0px;
  }
}
