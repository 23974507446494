body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f7f7f7;
}

.sign-on-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 2%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 2%;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid;
}

input[type='submit'] {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

input[type='submit']:hover {
  background-color: #3e8e41;
}

@media only screen and (max-width: 399px) {
  .sign-on-form {
    max-width: 80vw;
    overflow: scroll;
  }

  input[type='email'],
  input[type='password'] {
    width: 80%;
    padding: 1%;
  }
}
