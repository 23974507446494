.habits {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  margin-top: 2%;
}

.just-habit {
  margin-right: 2%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 1%;
  min-width: 33%;
  margin-bottom: 2%;
}

@media only screen and (max-width: 399px) {
  .habit-header {
    font-size: 15px;
  }
  .habit-subtext {
    font-size: 10px;
  }
  .habit-card {
    font-size: 10px;
  }
}
