.main-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  margin: 2%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
  font-size: 20px;
  color: #333333;
}

.main-tile:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 4px #16a0849b;
  cursor: pointer;
  color: #16a08496;
  font-weight: bold;
}

.main-tile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  margin: 2%;
  background-color: #ffffff;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.main-tile-header:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 4px #16a0849b;
  cursor: pointer;
  color: #16a08496;
  font-weight: bold;
}
